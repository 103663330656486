<template>
  <div
    style="height: 100vh;"
    class="d-flex justify-content-center align-items-center bg-white my-5"
    v-if="isLoading"
  >
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <b-card no-body v-else class="rounded-0">
    <b-card-header class="p-3 d-flex align-items-center justify-content-between bg-light">
      <div class="d-flex align-items-center justify-content-between">
        <b-input-group class="nowrap m-right-20" style="width: 400px;">
          <template #prepend>
            <b-input-group-text class="bg-white pr-0">
              <fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon>
            </b-input-group-text>
          </template>
          <template #append>
            <b-button variant="dark" v-on:click="clearFilter()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
          </template>
          <b-input placeholder="Suche" class="border-left-0" v-model="filter" debounce="600"></b-input>
        </b-input-group>
        <div class="d-flex align-items-center">
          <strong class="mr-3">Zeitraum&nbsp;von</strong>
          <b-input-group class="nowrap">
            <!-- <template #append>
              <b-button variant="dark" v-on:click="clearStartDate()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
            </template> -->
            <b-form-datepicker v-model="startDate" :min="startDateMin" :max="startDateMax" style="width:160px;" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-datepicker>
          </b-input-group>
          <strong class="mx-3">bis</strong>
          <b-input-group class="nowrap m-right-20">
            <!-- <template #append>
              <b-button variant="dark" v-on:click="clearEndDate()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
            </template> -->
            <b-form-datepicker v-model="endDate" :min="endDateMin" :max="endDateMax" style="width:160px;" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-datepicker>
          </b-input-group>
        </div>
        <div class="d-flex">
          <export-data-component :items="exportItems"></export-data-component>
        </div>
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="quantity"
        :per-page="perPage"
        class="mb-0"
      ></b-pagination>
    </b-card-header>

    <b-table
      ref="table"
      :items="orders"
      :fields="fields"
      class="mb-0"
      :filter="$store.state.filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      :per-page="perPage"
      :current-page="currentPage"
      :no-provider-paging="true"
      :no-provider-sorting="true"
      :no-provider-filtering="true"
      @filtered="onFiltered"
      :filter-included-fields="filterIncludedFields"
    >
      <template #cell(status)="data">
        <fa-icon
          :icon="['fa-regular', statusIcon(data.item.order.status)]"
          :class="`mr-1 ${statusClass(data.item.order.status)}`"
        ></fa-icon>
        {{ data.item.order.status }}
        {{ data.item.order.status | orderStatus }}
      </template>
      <template #cell(orderId)="data">
        <div v-if="data.item.order.lineItems.length > 0">
          <button class="btn btn-link p-0" v-b-modal="'modal-' + data.item.order.orderId">{{ data.item.order.orderId }}</button>
          <order-modal :id="'modal-' + data.item.order.orderId" :item="data.item"></order-modal>
        </div>
        <div v-else>{{ data.item.order.orderId }}</div>
      </template>
      <template #cell(creationDate)="data">
        {{ data.item.creationDate.toDate() | dateTime }}
      </template>
      <template #cell(searchType)="data">
        <button class="btn btn-link p-0" v-on:click="filter = '#' + data.item.order.orderType">{{ data.item.order.orderType }}</button>
      </template>
      <template #cell(orderSum)="data">
        <span v-if="data.item.order.status === '1008'">-</span>{{ data.item.orderSum | currency }}
      </template>
    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <h4 class="m-0">{{ sum | currency }}</h4>
          <small class="text-muted">{{ quantity }} Vorgänge</small>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase"
import { collection, getDocs, query, where } from "firebase/firestore"; 
import ExportDataComponent from '../components/ExportDataComponent.vue';
import OrderModal from '../components/OrderModal.vue';

export default {
  components: { ExportDataComponent, OrderModal },
  name: "HistoryView",
  data() {
    return {
      exportItems: [],
      perPage: 25,
      currentPage: 1,
      firestoreDocFormatSwitchTime: "2024-02-08 16:00:00",
      startDateMin: "2021-07-23",
      startDateMax: moment().format("YYYY-MM-DD"),
      startDate: moment().format("YYYY-MM") + "-01",
      endDateMin: "2021-07-23",
      endDateMax: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      sum: 0,
      quantity: 0,
      filter: this.$store.state.filter,
      isLoading: true,
      orders: [],
      sortBy: "creationDate",
      sortDesc: true,
      filterIncludedFields: [
        "resellerCustomernumber",
        "reseller",
        "resellerId",
        "customer",
        "customerId",
        "cotermDate",
        "orderId",
        "membershipId",
        "status",
        "orderSum",
        "searchType",
        "creationDate",
      ],
      fields: [
        {
          key: "resellerCustomernumber",
          label: "Kundennummer",
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return item.deluxeOrder.customercode;
          },
        },
        {
          key: "reseller",
          label: "Reseller",
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return item.reseller.companyProfile.companyName;
          },
        },
        {
          key: "resellerId",
          label: "Reseller-ID",
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return item.customer.resellerId;
          },
        },
        {
          key: "customer",
          label: "Endkunde",
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return item.customer.companyProfile.companyName;
          },
        },
        {
          key: "customerId",
          label: "Customer-ID",
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return item.customer.customerId;
          },
        },
        {
          key: "cotermDate",
          label: "Anniversary Date",
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return this.$options.filters.date(item.customer.cotermDate);
          },
        },
        {
          key: "orderId",
          label: "Order-/Transfer-ID",
          sortable: true,
        },
        {
          key: "membershipId",
          label: "VIP-Nr.",
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return item.order.membershipId;
          },
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "orderSum",
          label: "Summe",
          sortable: true,
        },
        {
          key: "searchType",
          label: "Art",
          sortable: true,
        },
        {
          key: "creationDate",
          label: "Creation Date",
          sortable: true,
        },
      ],
    };
  },
  async mounted() {
    await this.getOrders();
  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 - 75 + "px";
    },
  },
  watch: {
    filter(filter) {
      this.$store.commit("filter", filter);
    },
    async startDate() {
      await this.getOrders();
      this.$refs.table.refresh();
    },
    async endDate() {
      await this.getOrders();
      this.$refs.table.refresh();
    }
  },
  methods: {
    async getOrders() {
      try {
        this.isLoading = true
        this.orders = []
        this.sum = 0
        this.quantity= 0
        const colRef = collection(firestore, "dashboardOrders");
        const q = query(colRef, where("creationDate", ">=", new Date(`${moment(this.startDate).format("YYYY/MM/DD")} 00:00:00`)), where("creationDate", "<=", new Date(`${moment(this.endDate).format("YYYY/MM/DD")} 23:59:59`)));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (!doc.id.match(/^T-/g)) {
            data["searchType"] = "#" + data.order.orderType.toUpperCase();
            data.orderSum = 0;
            if (data?.deluxeOrder && data?.deluxeOrder.order_lines) {
              data.orderSum = data.deluxeOrder.order_lines.reduce((acc, line) => {
                return acc + line.unit_cost * line.quantity;
              }, 0);
            }
            this.orders.push(data);

            if (data.order.status !== "1008" && data.order.status !== "1004") {
              this.sum += data.orderSum;
            } else if (data.order.status === "1008") {
              this.sum += -data.orderSum;
            }
            
            this.quantity ++
          } else {
            if (data.membershipId) {
              data["searchType"] = "#" + data.type.toUpperCase();
              if (data?.lineItems) {
                const orderSum = data.lineItems.reduce((acc, line) => {
                  return acc + (line.price || 0) * line.quantity;
                }, 0);

                this.orders.push({
                  creationDate: data.creationDate,
                  orderSum: orderSum,
                  order: {
                    orderType: data.type.toUpperCase(),
                    creationDate: data.creationDate,
                    status: data.status,
                    orderSum: data.orderSum,
                    orderId: data.orderId,
                    membershipId: data.membershipId,
                    cotermDate: data.cotermDate,
                    lineItems: data.lineItems,
                  },
                  customer: {
                    companyProfile: {
                      companyName: data.customer,
                    },
                    customerId: data.customerId,
                    resellerId: data.resellerId,
                    cotermDate: data.cotermDate,
                  },
                  reseller: {
                    companyProfile: {
                      companyName: data.reseller,
                    },
                  },
                  deluxeOrder: {
                    customercode: data.customercode,
                    order_lines: data.lineItems,
                  },
                  searchType: "#" + data.type.toUpperCase(),
                });

                if (data.status !== "1008" && data.status !== "1004") {
                  this.sum += orderSum;
                } else if (data.status === "1008") {
                  this.sum += -orderSum;
                }

                this.quantity ++
              }
            } else {
              data["searchType"] = "#" + data.order.orderType.toUpperCase();
              data.orderSum = 0;
              if (data?.deluxeOrder && data?.deluxeOrder.order_lines) {
                data.orderSum = data.deluxeOrder.order_lines.reduce((acc, line) => {
                  return acc + line.unit_cost * line.quantity;
                }, 0);
              }
              this.orders.push(data);

              if (data.order.status !== "1008" && data.order.status !== "1004") {
                this.sum += data.orderSum;
              } else if (data.order.status === "1008") {
                this.sum += -data.orderSum;
              }

              this.quantity ++
            }
          }
        });

        this.exportItems = this.orders;

        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    clearFilter() {
      this.filter = "";
    },
    clearStartDate() {
      this.startDate = "2021-07-23";
    },
    clearEndDate() {
      this.endDate = moment().format("YYYY-MM-DD");
    },
    onFiltered(filteredItems) {
      this.sum = 0

      filteredItems.forEach((data) => {
        if (data.order.status !== "1008" && data.order.status !== "1004") {
          this.sum += data.orderSum;
        } else if (data.order.status === "1008") {
          this.sum += -data.orderSum;
        }
      });

      this.exportItems = filteredItems;

      this.quantity = filteredItems.length
    }
  }
};
</script>

<style scoped>
.nowrap {
  flex-wrap: nowrap;
}
.m-right-20 {
  margin-right: 20px;
} 
</style>