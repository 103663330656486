<template>
  <div>
    <template v-if="!settings.partnerStore || showClLogo">
      <div class="d-flex inline-flex">
        <div id="logo" class="mr-3" :style="`width: ${width}; height: ${height}; background-color: ${fill};`"></div> 
        <slot />
      </div>
    </template>
    <template v-else>
      <img class="img img-responsive" :style="`max-height: ${height} !important;`" src="../../public/logo.png"/>
      <br v-if="lineBreak"/>
      <slot />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LogoComponent",
  props: {
    height: String,
    width: String,
    fill: String,
    lineBreak: Boolean,
    showClLogo: Boolean
  },
  computed: mapState(["settings"]),
};
</script>

<style scoped>
#logo {
  mask: url(/logo.svg) no-repeat center;
}
</style>
