import Vue from "vue";

import routes from "./routes.js";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import { getAuth } from "firebase/auth"

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await getAuth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if ((to.name === "SignInView" || to.name === "TokenLogin") && currentUser) next({ name: "HistoryView" });
  else if (requiresAuth && currentUser) next();
  else if (requiresAuth && !currentUser) next({ name: "SignInView" });
  else next();
});

const defaultTitle = "";
router.afterEach((to) => {  
  document.title += to.meta.title || defaultTitle;
});

export default router;
