<template>
  <span>
   {{ value | currency }} 
   <span v-b-tooltip.hover :title="(value * USD) | currencyUSD">
   <fa-icon :icon="['fa-regular', 'fa-circle-dollar']" 
            class="ml-1 text-muted"
          ></fa-icon></span>
   </span>
</template>

<script>
import { firestore } from "../plugins/firebase"
import { doc, getDoc } from "firebase/firestore";


export default {
  name: "Currency",
  props: {
    value: Number,
  }, 
  data() {
    return {
      USD: 0,
    }
  },
  async mounted() { 
    const docRef = await doc(firestore, "dashboardSettings", "currencyValues");
    getDoc(docRef).then((doc)  =>  {
      this.USD = doc.data().USD;
      console.log(doc.data())
    })
  }
};
</script>
