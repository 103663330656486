<template>
  <b-modal :content-class="contentClass" :footer-class="footerClass" :body-class="bodyClass" :header-class="headerClass"
    hide-footer :id="id" size="xl">
    <template #modal-title>
      <span v-b-tooltip.hover.bottom="'Order-ID'">{{
        item.order.orderId
      }}</span>
      <small v-if="item.deluxeOrder.order_reference" class="text-muted mt-2 d-block" v-b-tooltip.hover
        v-html="'Bestellnummer: ' + item.deluxeOrder.order_reference"></small>
      <small v-if="item.order.referenceOrderId" class="text-muted mt-2 d-block" v-b-tooltip.hover
        v-html="'Referenzauftrag: ' + item.order.referenceOrderId"></small>
    </template>
    <table class="table mb-0">
      <thead>
        <tr>
          <th>Produkt</th>
          <th>Status</th>
          <th>Menge</th>
          <th>Preis</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(lineItem, lineItemIndex) in lineItems" :key="`item-${lineItemIndex}`">
          <td>
            {{ lineItem.productName | productNameWithoutLevel }}<br />
            <small class="text-muted" v-b-tooltip.hover :title="'Herstellerartikelnummer'">{{ lineItem.offerId }}</small>
          </td>
          <td>
            <div :class="statusClass(lineItem.status)">
              <fa-icon :icon="['fa-regular', statusIcon(lineItem.status)]" class="mr-1"></fa-icon>{{ lineItem.status |
                orderStatus }}
            </div>
          </td>
          <td>{{ lineItem.quantity }}</td>
          <td>
            <span v-if="item.type === 'Return'">-</span>{{ lineItem.price | currency }}
          </td>
        </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
import { firestore } from "../plugins/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

export default {
  props: {
    id: String,
    item: Object
  },
  computed: {
    lineItems() {
      return this.item.order.lineItems.map((lineItem) => {
        const orderLine = this.item.deluxeOrder.order_lines.find((order_line) => (order_line.vipmp_offer_id === lineItem.offerId));

        if (orderLine) {
          return {
            ...lineItem,
            quantity: orderLine.quantity,
            price: orderLine.unit_cost,
          };
        } else {
          return lineItem;
        }
      });
    }
  },
  data() {
    return {
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
    }
  },
  methods: {
statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    async getProducts() {
      const promises = this.item.order.lineItems.map((lineItem) => {
        const colRef = collection(firestore, "products");
        const q = query(colRef, where("manufacturerItemNumber", "==", lineItem.offerId));
        return getDocs(q);
      });

      const promiseResults = await Promise.allSettled(promises);

      promiseResults.forEach((result, index) => {
        if (result.status === "fulfilled") {
          if (result.value.docs.length > 0) {
            const doc = result.value.docs[0].data();
            this.item.order.lineItems[index].productName = doc.productName;
          } else {
            this.item.order.lineItems[index].productName = "Produkt nicht gefunden";
          }
        }
      });
    },
  },
  mounted() {
    this.getProducts();
  },
}
</script>
