<template>
  <div class="d-flex justify-content-center align-items-center bg-white my-5" style="height: 100vh;" v-if="isLoading">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>

  <b-card v-else no-body class="rounded-0">
    <b-card-header class="p-3 bg-light d-flex justify-content-between">
      <b-input-group style="width: 400px">
        <template #prepend>
          <b-input-group-text class="bg-white pr-0">
            <fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon>
          </b-input-group-text>
        </template>
        <template #append>
          <b-button variant="dark" v-on:click="clearFilter()"
            ><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon
          ></b-button>
        </template>
        <b-input placeholder="Suche" class="border-left-0" v-model="filter" debounce="600"></b-input>
      </b-input-group>
      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" class="mb-0"></b-pagination>
    </b-card-header>
    <b-table
      :items="renewals"
      :fields="fields"
      class="mb-0"
      :filter="$store.state.renewalsFilter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      ref="table"
      :per-page="perPage"
      :current-page="currentPage"
      :no-provider-paging="true"
      :no-provider-sorting="true"
      :no-provider-filtering="true"
      @filtered="onFiltered"
    >
      <template #cell(limit)="data">
        {{ data.item.limit | limitFilter | currency }}
      </template>
      <template #cell(resellerCustomernumber)="data">
        {{ data.item.deluxeOrder.customercode }}
      </template>
      <template #cell(reseller)="data">
        {{ data.item.reseller.companyProfile.companyName }}
      </template>
      <template #cell(resellerId)="data">
        {{ data.item.customer.resellerId }}
      </template>
      <template #cell(customer)="data">
        {{ data.item.customer.companyProfile.companyName }}
      </template>
      <template #cell(customerId)="data">
        {{ data.item.customer.customerId }}
      </template>
      <template #cell(orderId)="data">
        <div v-if="data.item.order.lineItems.length > 0">
          <button class="btn btn-link p-0" v-b-modal="'modal-' + data.item.order.orderId">
            {{ data.item.order.orderId }}
          </button>
          <order-modal :id="'modal-' + data.item.order.orderId" :item="data.item"></order-modal>
        </div>
        <div v-else>{{ data.item.order.orderId }}</div>
      </template>
      <template #cell(cotermDate)="data">
        {{ data.item.customer.cotermDate | date }}
      </template>
      <template #cell(creationDate)="data">
        {{ data.item.order.creationDate | dateTime }}
      </template>
      <template #cell(orderSum)="data">
        {{ data.item.orderSum | currency }}
      </template>
      <template #cell(action)="data">
        <b-button v-b-modal="'case-modal-' + data.item.order.orderId" variant="outline-primary">Fall lösen</b-button>
        <resolve-case-modal-component
          :id="'case-modal-' + data.item.order.orderId"
          :item="data.item"
        ></resolve-case-modal-component>
      </template>
    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center text-muted">{{ rows }} Aufträge</div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { firestore } from "../plugins/firebase";
import { collection, onSnapshot, where, query } from "firebase/firestore";
import OrderModal from "../components/OrderModal.vue";
import resolveCaseModalComponent from "../components/resolveCaseModalComponent.vue";

export default {
  name: "InsufficientLimitQueueView",
  components: { OrderModal, resolveCaseModalComponent },
  data() {
    return {
      unsubscribeFromOrdersCollection: null,
      rows: 0,
      perPage: 25,
      currentPage: 1,
      filter: "",
      isLoading: true,
      renewals: [],
      sortBy: "creationDate",
      sortDesc: true,
      fields: [
        {
          key: "resellerCustomernumber",
          label: "Kundennummer",
          sortable: true,
        },
        {
          key: "reseller",
          label: "Reseller",
          sortable: true,
        },
        {
          key: "resellerId",
          label: "Reseller-ID",
          sortable: true,
        },
        {
          key: "customer",
          label: "Endkunde",
          sortable: true,
        },
        {
          key: "customerId",
          label: "Customer-ID",
          sortable: true,
        },
        {
          key: "cotermDate",
          label: "Anniversary Date",
          sortable: true,
        },
        {
          key: "creationDate",
          label: "Creation Date",
          sortable: true,
        },
        {
          key: "orderId",
          label: "Order-ID",
          sortable: true,
        },
        {
          key: "orderSum",
          label: "Summe",
          sortable: true,
        },

        {
          key: "limit",
          label: "Limit des Kunden",
          sortable: false,
        },
        {
          key: "action",
          label: "",
          sortable: false,
        },
      ],
    };
  },
  filters: {
    limitFilter(value) {
      const sum = value.limit_offenepostenbrutto - value.offene_rechnungen;
      return sum;
    },
  },
  mounted() {
    this.subscribeOrdersCollection();
  },
  unmounted() {
    this.unsubscribeFromOrdersCollection();
  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 - 51 + "px";
    },
  },
  watch: {
    filter(filter) {
      this.$store.commit("customerFilter", filter);
    },
  },
  methods: {
    async subscribeOrdersCollection() {
      try {
        const q = query(
          collection(firestore, "orders"),
          where("showInInsufficientLimitQueue", "==", true)
        );

        this.unsubscribeFromOrdersCollection = onSnapshot(q, (querySnapshot) => {
          this.renewals = [];

          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.docId = doc.id;
            data.orderSum = 0;
            if (data?.deluxeOrder && data?.deluxeOrder.order_lines) {
              data.orderSum = data.deluxeOrder.order_lines.reduce((acc, line) => {
                return acc + line.unit_cost * line.quantity;
              }, 0);
            }
            this.renewals.push(data);
          });

          this.rows = this.renewals.length;
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    clearFilter() {
      this.filter = "";
    },
    onFiltered(filteredItems) {
      console.log(filteredItems.length);
      this.rows = filteredItems.length;
    },
  },
};
</script>
