<template>
  <b-button v-on:click="$emit('click')" type="submit" :variant="variant" :disabled="state === 'working' || state === 'missingFields' || state === 'blocked'" :block="block">
    <b-spinner v-if="state === 'working'" label="Spinning" variant="light" small></b-spinner>
    <span v-else>{{ label }}</span>
  </b-button>
</template>

<script>
export default {
  name: "SubmitButtonComponent",
  props: {
    state: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: () => {
        return "Speichern";
      },
    },
    block: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
};
</script>
