<template>
  <b-modal
    :content-class="contentClass"
    :footer-class="footerClass"
    :body-class="bodyClass"
    :header-class="headerClass"
    :id="id"
  >
    Der Auftrag mit der Order-ID <strong>{{ item.order.orderId }}</strong> kann freigegeben oder storniert werden.
    <template #modal-footer>
      <submit-button-component
        :state="cancelBtnState"
        label="Stornieren"
        variant="danger"
        @click="displayQuestionModal('cancel')"
      ></submit-button-component>
      <submit-button-component
        :state="releaseBtnState"
        label="Freigeben"
        variant="primary"
        @click="displayQuestionModal('release')"
      ></submit-button-component>
    </template>
  </b-modal>
</template>

<script>
import SubmitButtonComponent from "./SubmitButtonComponent.vue";
import { firestore, functions } from "../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export default {
  components: {
    SubmitButtonComponent,
  },
  props: {
    id: String,
    item: Object,
  },
  data() {
    return {
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
      cancelBtnState: "",
      releaseBtnState: "",
    };
  },
  methods: {
    displayQuestionModal(type) {
      const label = type === "release" ? "freigeben" : "stornieren";
      const message = `Bist du dir sicher, dass du den Auftrag ${label} möchtest?`;
      const okVariant = type === "release" ? "primary" : "danger";
      const settings = {
        title: `Auftrag ${label}`,
        size: "s",
        okVariant: okVariant,
        okTitle: "Ja",
        cancelTitle: "Nein",
        footerClass: this.footerClass,
        headerClass: this.headerClass,
        bodyClass: this.bodyClass,
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
      }

      this.$bvModal.msgBoxConfirm(message, settings)
        .then((answer) => {
          if (answer) {
            if (type === "release") {
              this.release();
            } else {
              this.cancel();
            }
          }
        });
    },
    async cancel() {
      this.cancelBtnState = "working";

      const {reseller, customer, order} = this.item;

      const config = {
        customerNumber: reseller.customernumber,
        resellerId: reseller.resellerId,
        customerId: customer.customerId,
        complete: true,
        body: {
          referenceOrderId: order.orderId,
          orderType: "RETURN",
          externalReferenceId: order.externalReferenceId,
          currencyCode: order.currencyCode,
          lineItems: order.lineItems,
        },
      };

      const cancelOrder = httpsCallable(functions, "cancelOrder");
      const orderWasCancelled = cancelOrder(config)
        .catch((error) => {
          console.error("error", error);
        })
        .finally(() => {
          this.cancelBtnState = "";
          this.$bvModal.hide(this.id);
        });

      if (orderWasCancelled) {
        const docRef = doc(firestore, "orders", this.item.docId);

        await updateDoc(docRef, {
          showInInsufficientLimitQueue: false,
        });
      }
    },
    async release() {
      this.releaseBtnState = "working";

      const docRef = doc(firestore, "orders", this.item.docId);

      await updateDoc(docRef, {
        sufficientLimit: true,
        showInInsufficientLimitQueue: false,
      });

      this.releaseBtnState = "";
      this.$bvModal.hide(this.id);
    },
  },
};
</script>
