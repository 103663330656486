<template>
  <b-navbar toggleable="xl" type="dark" variant="primary">
    <div fluid class="my-1 d-flex justify-content-between flex-grow-1">
    <b-navbar-brand class="d-flex align-items-center py-0" :to="{ name: 'HistoryView' }">
      <logo-component :fill="'#ffffff'" :height="'40px'" :width="'40px'" class="mr-1"> </logo-component>ACS Dashboard
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" class="my-1">
      <template #default="{ expanded }">
        <fa-icon v-if="expanded" :icon="['fa-regular','fa-bars']"></fa-icon>
        <fa-icon v-else :icon="['fa-regular','fa-bars']"></fa-icon>
      </template>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item
          :active="$route.name === 'HistoryView'"
          :to="{ name: 'HistoryView' }">
          Historie
        </b-nav-item>
        <b-nav-item
          :active="$route.name === 'SummaryView'"
          :to="{ name: 'SummaryView' }">
          Zusammenfassung
        </b-nav-item>
        <b-nav-item
          :active="$route.name === 'InsufficientLimitQueueView'"
          :to="{ name: 'InsufficientLimitQueueView' }">
          Renewal-Warteschlange
        </b-nav-item>
        <b-nav-item
          :active="$route.name === 'ResellersView'"
          :to="{ name: 'ResellersView' }">
          Reseller
        </b-nav-item>
        <b-nav-item
          :active="$route.name === 'CustomersView'"
          :to="{ name: 'CustomersView' }">
          Kunden
        </b-nav-item>
        <b-nav-item
          :active="$route.name === 'RegistrationsView'"
          :to="{ name: 'RegistrationsView' }">
          Offene Registrierungen
        </b-nav-item>
        <b-nav-item
          :active="$route.name === 'TransfersView'"
          :to="{ name: 'TransfersView' }">
          Offene Transfers
        </b-nav-item>
        <b-nav-item
          :active="$route.name === 'AnalyticView'"
          :to="{ name: 'AnalyticView' }">
          Analytic
        </b-nav-item>
        <b-nav-item
          v-if="settings.partnerStore"
          :active="$route.name === 'ProductImportView'"
          :to="{ name: 'ProductImportView' }">
          Produktimport
        </b-nav-item>
        <b-nav-item-dropdown right text="Tools">
          <fetch-transfer-licenses-component></fetch-transfer-licenses-component>
          <reseller-swap-component></reseller-swap-component>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item v-on:click="signOutUser"><fa-icon :icon="['fa-regular', 'fa-arrow-right-from-bracket']" class="mr-2"></fa-icon>Abmelden</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </div>
  </b-navbar>
</template>

<script>
import { auth } from "../plugins/firebase"
import { signOut } from "firebase/auth";
import LogoComponent from "../components/LogoComponent.vue";
import FetchTransferLicensesComponent from '../components/FetchTransferLicensesComponent.vue';
import ResellerSwapComponent from '../components/ResellerSwapComponent.vue';
import { mapState } from "vuex";

export default {
  name: "NavigationComponent",
  components: {
    LogoComponent,
    FetchTransferLicensesComponent,
    ResellerSwapComponent
  },
  methods: {
    signOutUser() {
      signOut(auth)
        .then(() => this.$router.replace("/"))
        .catch((error) => console.error(error.message));
    }
  },
  data() {
    return {
      filter: ""
    }
  },
  computed: mapState(["settings"]),
};
</script>

<style scoped>
.b-dropdown-text {
  font-weight: normal;
}
#target-logo {
  max-height: 36px;
}
</style>
