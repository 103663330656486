<template>
  <div>
    <b-dropdown-item  v-b-modal="'reseller-swap-modal'">Reseller Swap /<br>Kundendaten Laden</b-dropdown-item>
    <b-modal 
      id="reseller-swap-modal" 
      ref="reseller-swap-modal" 
      :content-class="contentClass"
      hide-footer
      :body-class="bodyClass"
      :header-class="headerClass">
      <b-form-group>
        <label>Customer-ID</label>
        <b-input v-model="customerId"></b-input>
      </b-form-group>
      <submit-button-component v-on:click="submit()" :state="submitButtonState" label="Ausführen" :block="false" variant="dark"></submit-button-component>
    </b-modal>
  </div>
</template>

<script>
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";

export default {
  name: "FetchMissingRenewalsComponent",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      contentClass: ["border-0", "shadow-lg"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
      customerId: "",
    }
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    async submit() {
      if (this.customerId === "") {
        return;
      }

      this.isWorking = true;

      const dashboardResellerSwap = httpsCallable(functions, "dashboardResellerSwap", {timeout: 540000});
      await dashboardResellerSwap({
        customerId: this.customerId,
      })
        .then(() => {
          this.isWorking = false;
          this.$refs["reseller-swap-modal"].hide();
          this.$store.dispatch("alertSuccess", {
            message: "Fertig",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          this.isWorking = false;
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
    },
  },
}
</script>