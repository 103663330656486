export default {
  partnerStore: false,
  companyName: "ComLine",
  storeUrl() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      return "http://localhost:8081";
    } else {
      return "https://adobe-cloud-store.comline.app";
    }
  },
  firebaseConfig: {
    apiKey: "AIzaSyDylNqfB_hQrI43Vog66-cwWcdVps17LPw",
    authDomain: "clgmbh-marketplace.firebaseapp.com",
    projectId: "clgmbh-marketplace",
    storageBucket: "clgmbh-marketplace.appspot.com",
    messagingSenderId: "990039872990",
    appId: "1:990039872990:web:e75c0d6bb2ceb4f101f94f",
  },
};