import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import settings from "../../settings";

const firebase = initializeApp(settings.firebaseConfig);
const auth = getAuth(firebase);
const firestore = getFirestore(firebase);
const functions = getFunctions(firebase, "europe-west3");

if (location.hostname === 'localhost' || location.hostname === "127.0.0.1" || location.hostname.match(/^192\.168\./)) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
  self.FIREBASE_APPCHECK_DEBUG_TOKEN ||= true
  process.env.VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN || console.info("Erstelle eine Datei mit dem Namen  \".env.local\" in \"hosting/dashboard\" um darin den VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN zu speichern.")
}

const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider('6LdeyzInAAAAAMMHLJu9gW4S4jlqgRSfpAdBgMfS'),
  isTokenAutoRefreshEnabled: true
});

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { auth, firestore, functions, appCheck };
