import moment from "moment-timezone";

const dateTime = (date) => {
  if (!date) {
    return date
  }
  return moment(date).format("DD.MM.YYYY HH:MM:ss");
};

const date = (date) => {
  if (!date) {
    return date
  }
  return moment(date).format("DD.MM.YYYY");
};

const dateShort = (date) => {
  if (!date) {
    return date
  }
  return moment(date).format("DD.MM.YY");
};

const time = (date) => {
  if (!date) {
    return date
  }
  return moment(date).format("HH:mm:ss");
};

const accountStatus = (status) => {
  if (status === "1000") {
    return "Aktiv";
  } else if (status === "1002") {
    return "Ausstehend";
  } else if (status === "1004") {
    return "Inaktiv";
  } else if (status === "1010") {
    return "Inaktiv - Adresse ist ungültig";
  } else if (status === "1012") {
    return "Inaktiv - Konto ist gesperrt";
  } else if (status === "1014") {
    return "Inaktiv - Der Kunde existiert bereits mit demselben Firmennamen und primärem Administrator";
  } else if (status === "404") {
    return "Umgezogen";
  } else {
    return "unknown";
  }
};

const orderStatus = (status) => {
  if (status === "1000") {
    return "Abgeschlossen";
  } else if (status === "1002") {
    return "In Bearbeitung";
  } else if (status === "1004") {
    return "Gescheitert";
  } else if (status === "1008") {
    return "Storniert";
  } else if (status === "1020") {
    return "Gescheitert - Distributor ist inaktiv";
  } else if (status === "1022") {
    return "Gescheitert - Reseller ist inaktiv";
  } else if (status === "1024") {
    return "Gescheitert - Kunde ist inaktiv";
  } else if (status === "1026") {
    return "Gescheitert - Kunden-ID ist ungültig";
  }
};

const subscriptionStatus = (status) => {
  if (status === "1000") {
    return "Aktiv";
  } else if (status === "1002") {
    return "Pending";
  } else if (status === "1004") {
    return "Inaktiv";
  } else if (status === "init") {
    return "transferNotSubmitted";
  }
};

const currency = (value, maximumFractionDigits) => {
  if (!maximumFractionDigits) {
    maximumFractionDigits = 2;
  }
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
};

const currencyUSD = (value, maximumFractionDigits) => {
  if (!maximumFractionDigits) {
    maximumFractionDigits = 2;
  }
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
};

const currencyWithNoFractions = (value) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const productNameWithoutLevel = (name) => {
  if (name) {
    const result = name.replace(/\s*\([^)/]*\)\s*(\**)/g, "");
    return result;
  } else {
    return "";
  }
};

export {
  dateTime,
  orderStatus,
  currency,
  currencyUSD,
  date,
  dateShort,
  accountStatus,
  time,
  currencyWithNoFractions,
  productNameWithoutLevel,
  subscriptionStatus
};
