<template>
  <b-button variant="dark" v-on:click="exportData()" class="nowrap">Daten&nbsp;exportieren</b-button>
</template>

<script>
import { utils, writeFileXLSX } from 'xlsx';
// import moment from 'moment-timezone';

export default {
  name: "ExportAnalyticDataComponent",
  props: {
    exportItems: { type: Array },
  },
  methods: {
    exportData() {
      const exportItems = this.exportItems.map(element => {
        return {
          resellerId: element.resellerId,
          resellerName: element.resellerName,
          customerId: element.customerId,
          companyName: element.companyProfile.companyName,
          cotermDate: element.cotermDate,
          cancelationDate: element.cancelationDate,
          previousActiveValue: element.previousActiveValue,
          potentialRenewalValue: element.potentialRenewalValue,
          activeSubscriptionsValue: element.activeValue,
          renewalValue: element.renewalValue,
          subscriptionsActiveNow: element.subscriptionAnalysisActiveNowCount,
          renewalActiveNow: element.subscriptionAnalysisWillRenewalCount,
          realRenewal: element.realRenewal,
          }
      })
      const worksheet = utils.json_to_sheet(exportItems);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Daten");
      writeFileXLSX(workbook, "acs-dashboard-export.xlsx");
    }
  }
}
</script>