<template>
  <div class="d-flex justify-content-center align-items-center bg-white my-5" style="height: 100vh;" v-if="isLoading">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <div v-else>
    <table class="table mb-0 table-bordered text-body">
      <thead>
        <tr class="bg-light">
          <th scope="col">&nbsp;</th>
          <th scope="col" class="text-body" colspan="2">Heute</th>
          <th scope="col" class="text-body" colspan="2">Aktueller Monat</th>
          <th scope="col" class="text-body" colspan="2">
            <div class="d-flex justify-content-between align-items-center">
              Aktuelles Adobe Quartal <span v-if="startEndDate !== ''">({{ startEndDate.start | date }} - {{ startEndDate.end | date }})</span>
              <fa-icon :icon="['fa-regular', 'fa-gear']" v-b-modal.settings-modal></fa-icon>
            </div>
          </th>
          <th scope="col" class="text-body" colspan="2">Aktuelles Jahr</th>
        </tr>
        <tr class="bg-light">
          <th scope="col">&nbsp;</th>
          <th scope="col" class="text-body text-right">Vorgänge</th>
          <th scope="col" class="text-body text-right">Volumen</th>
          <th scope="col" class="text-body text-right">Vorgänge</th>
          <th scope="col" class="text-body text-right">Volumen</th>
          <th scope="col" class="text-body text-right">Vorgänge</th>
          <th scope="col" class="text-body text-right">Volumen</th>
          <th scope="col" class="text-body text-right">Vorgänge</th>
          <th scope="col" class="text-body text-right">Volumen</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" class="bg-light text-body">Order</th>
          <td class="text-right">{{ dayOrderQuantity }}</td>
          <td class="text-right">{{ dayOrderVolume | currencyWithNoFractions }}</td>
          <td class="text-right">{{ monthOrderQuantity }}</td>
          <td class="text-right">{{ monthOrderVolume | currencyWithNoFractions }}</td>
          <td class="text-right">
            <span v-if="startEndDate !== ''">{{ quarterOrderQuantity }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-right">
            <span v-if="startEndDate !== ''">{{ quarterOrderVolume | currencyWithNoFractions }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-right">{{ yearOrderQuantity }}</td>
          <td class="text-right">{{ yearOrderVolume | currencyWithNoFractions }}</td>
        </tr>
        <tr>
          <th scope="row" class="bg-light text-body">Renewal</th>
          <td  class="text-right">{{ dayRenewalQuantity }}</td>
          <td  class="text-right">{{ dayRenewalVolume | currencyWithNoFractions }}</td>
          <td  class="text-right">{{ monthRenewalQuantity }}</td>
          <td  class="text-right">{{ monthRenewalVolume | currencyWithNoFractions }}</td>
          <td  class="text-right">
            <span v-if="startEndDate !== ''">{{ quarterRenewalQuantity }}</span>
            <span v-else>-</span>
          </td>
          <td  class="text-right">
            <span v-if="startEndDate !== ''">{{ quarterRenewalVolume | currencyWithNoFractions }}</span>
            <span v-else>-</span>
          </td>
          <td  class="text-right">{{ yearRenewalQuantity }}</td>
          <td  class="text-right">{{ yearRenewalVolume | currencyWithNoFractions }}</td>
        </tr>
        <tr>
          <th scope="row" class="bg-light text-body">Transfer</th>
          <td  class="text-right">{{ dayTransferQuantity }}</td>
          <td  class="text-right">{{ dayTransferVolume | currencyWithNoFractions }}</td>
          <td  class="text-right">{{ monthTransferQuantity }}</td>
          <td  class="text-right">{{ monthTransferVolume | currencyWithNoFractions }}</td>
          <td  class="text-right">
            <span v-if="startEndDate !== ''">{{ quarterTransferQuantity }}</span>
            <span v-else>-</span>
          </td>
          <td  class="text-right">
            <span v-if="startEndDate !== ''">{{ quarterTransferVolume | currencyWithNoFractions }}</span>
            <span v-else>-</span>
          </td>
          <td  class="text-right">{{ yearTransferQuantity }}</td>
          <td  class="text-right">{{ yearTransferVolume | currencyWithNoFractions }}</td>
        </tr>
        <tr>
          <th scope="row" class="bg-light text-body">Gesamt</th>
          <td  class="text-right">{{ dayQuantitySum }}</td>
          <td  class="text-right">{{ dayVolumeSum | currencyWithNoFractions }}</td>
          <td  class="text-right">{{ monthQuantitySum }}</td>
          <td  class="text-right">{{ monthVolumeSum | currencyWithNoFractions }}</td>
          <td  class="text-right">
            <span v-if="startEndDate !== ''">{{ quarterQuantitySum }}</span>
            <span v-else>-</span>
          </td>
          <td  class="text-right">
            <span v-if="startEndDate !== ''">{{ quarterVolumeSum | currencyWithNoFractions }}</span>
            <span v-else>-</span>
          </td>
          <td  class="text-right">{{ yearQuantitySum }}</td>
          <td  class="text-right">{{ yearVolumeSum | currencyWithNoFractions }}</td>
        </tr>
      </tbody>
    </table>
    <b-modal
      id="settings-modal"
      no-close-on-esc
      no-close-on-backdrop
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      title="Quartalseinstellungen"
      size="lg"
      v-on:ok="save()"
    >
      <div class="d-flex align-items-center mb-3">
        <span class="mr-3">1.&nbsp;Quartal</span>
        <b-form-datepicker v-model="firstQuarterStartDate"></b-form-datepicker>
        <span class="mx-3">bis</span>
        <b-form-datepicker v-model="firstQuarterEndDate"></b-form-datepicker>
      </div>
      <div class="d-flex align-items-center mb-3">
        <span class="mr-3">2.&nbsp;Quartal</span>
        <b-form-datepicker v-model="secondQuarterStartDate"></b-form-datepicker>
        <span class="mx-3">bis</span>
        <b-form-datepicker v-model="secondQuarterEndDate"></b-form-datepicker>
      </div>
      <div class="d-flex align-items-center mb-3">
        <span class="mr-3">3.&nbsp;Quartal</span>
        <b-form-datepicker v-model="thirdQuarterStartDate"></b-form-datepicker>
        <span class="mx-3">bis</span>
        <b-form-datepicker v-model="thirdQuarterEndDate"></b-form-datepicker>
      </div>
      <div class="d-flex align-items-center">
        <span class="mr-3">4.&nbsp;Quartal</span>
        <b-form-datepicker v-model="fourthQuarterStartDate"></b-form-datepicker>
        <span class="mx-3">bis</span>
        <b-form-datepicker v-model="fourthQuarterEndDate"></b-form-datepicker>
      </div>
      <template #modal-footer="{ cancel, ok }">
        <b-button variant="outline-secondary" v-on:click="cancel()"> Abbrechen </b-button>
        <b-button variant="primary" v-on:click="ok()"> Speichern </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase";
import { collection, getDocs } from "firebase/firestore";

export default {
  name: "SummaryView",
  data() {
    return {
      firstQuarterStartDate: moment().format("YYYY-MM-DD"),
      firstQuarterEndDate: moment().format("YYYY-MM-DD"),
      secondQuarterStartDate: moment().format("YYYY-MM-DD"),
      secondQuarterEndDate: moment().format("YYYY-MM-DD"),
      thirdQuarterStartDate: moment().format("YYYY-MM-DD"),
      thirdQuarterEndDate: moment().format("YYYY-MM-DD"),
      fourthQuarterStartDate: moment().format("YYYY-MM-DD"),
      fourthQuarterEndDate: moment().format("YYYY-MM-DD"),
      isLoading: true,
      dayQuantitySum: 0,
      dayVolumeSum: 0,
      monthQuantitySum: 0,
      monthVolumeSum: 0,
      quarterQuantitySum: 0,
      quarterVolumeSum: 0,
      yearQuantitySum: 0,
      yearVolumeSum: 0,
      dayOrderQuantity: 0,
      dayRenewalQuantity: 0,
      dayTransferQuantity: 0,
      monthOrderQuantity: 0,
      monthRenewalQuantity: 0,
      monthTransferQuantity: 0,
      quarterOrderQuantity: 0,
      quarterRenewalQuantity: 0,
      quarterTransferQuantity: 0,
      yearOrderQuantity: 0,
      yearRenewalQuantity: 0,
      yearTransferQuantity: 0,
      dayOrderVolume: 0,
      dayRenewalVolume: 0,
      dayTransferVolume: 0,
      monthOrderVolume: 0,
      monthRenewalVolume: 0,
      monthTransferVolume: 0,
      quarterOrderVolume: 0,
      quarterRenewalVolume: 0,
      quarterTransferVolume: 0,
      yearOrderVolume: 0,
      yearRenewalVolume: 0,
      yearTransferVolume: 0,
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
    };
  },
  mounted() {
    this.getOrders();

    if (this.$store.state.resellerData !== null) {
      this.firstQuarterStartDate = this.$store.state.resellerData.summaryQuarterSettings["1"].start;
      this.firstQuarterEndDate = this.$store.state.resellerData.summaryQuarterSettings["1"].end;

      this.secondQuarterStartDate = this.$store.state.resellerData.summaryQuarterSettings["2"].start;
      this.secondQuarterEndDate = this.$store.state.resellerData.summaryQuarterSettings["2"].end;

      this.thirdQuarterStartDate = this.$store.state.resellerData.summaryQuarterSettings["3"].start;
      this.thirdQuarterEndDate = this.$store.state.resellerData.summaryQuarterSettings["3"].end;

      this.fourthQuarterStartDate = this.$store.state.resellerData.summaryQuarterSettings["4"].start;
      this.fourthQuarterEndDate = this.$store.state.resellerData.summaryQuarterSettings["4"].end;
    }
  },
  computed: {
    startEndDate() {
      if (this.$store.state.resellerData !== null) {
        let thisQuarter = 1;

        Object.values(this.$store.state.resellerData.summaryQuarterSettings).forEach((quarterSetting, index) => {
          if (moment().isBetween(quarterSetting.start, quarterSetting.end)) {
            thisQuarter = index + 1;
          }
        });

        return this.$store.state.resellerData.summaryQuarterSettings[thisQuarter];
      }

      return "";
    },
  },
  watch: {
    startEndDate(newValue, oldValue) {
      if (newValue.end !== oldValue.end || newValue.start !== oldValue.start ) {
        this.getOrders();
      }
    },
  },
  methods: {
    async save() {
      this.$store.dispatch("resellerData", {
        1: {
          start: this.firstQuarterStartDate,
          end: this.firstQuarterEndDate,
        },
        2: {
          start: this.secondQuarterStartDate,
          end: this.secondQuarterEndDate,
        },
        3: {
          start: this.thirdQuarterStartDate,
          end: this.thirdQuarterEndDate,
        },
        4: {
          start: this.fourthQuarterStartDate,
          end: this.fourthQuarterEndDate,
        },
      });
    },
    async getOrders() {
      this.dayQuantitySum = 0;
      this.dayVolumeSum = 0;
      this.monthQuantitySum = 0;
      this.monthVolumeSum = 0;
      this.quarterQuantitySum = 0;
      this.quarterVolumeSum = 0;
      this.yearQuantitySum = 0;
      this.yearVolumeSum = 0;
      this.dayOrderQuantity = 0;
      this.dayRenewalQuantity = 0;
      this.dayTransferQuantity = 0;
      this.monthOrderQuantity = 0;
      this.monthRenewalQuantity = 0;
      this.monthTransferQuantity = 0;
      this.quarterOrderQuantity = 0;
      this.quarterRenewalQuantity = 0;
      this.quarterTransferQuantity = 0;
      this.yearOrderQuantity = 0;
      this.yearRenewalQuantity = 0;
      this.yearTransferQuantity = 0;
      this.dayOrderVolume = 0;
      this.dayRenewalVolume = 0;
      this.dayTransferVolume = 0;
      this.monthOrderVolume = 0;
      this.monthRenewalVolume = 0;
      this.monthTransferVolume = 0;
      this.quarterOrderVolume = 0;
      this.quarterRenewalVolume = 0;
      this.quarterTransferVolume = 0;
      this.yearOrderVolume = 0;
      this.yearRenewalVolume = 0;
      this.yearTransferVolume = 0;

    
      try {
        const querySnapshot = await getDocs(collection(firestore, "dashboardOrders"));
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (!doc.id.match(/^T-/g)) {
            data.orderSum = 0;
            if (data?.deluxeOrder && data?.deluxeOrder.order_lines) {
              data.orderSum = data.deluxeOrder.order_lines.reduce((acc, line) => {
                return acc + line.unit_cost * line.quantity;
              }, 0);
            }

            if (
              (data.order.orderType === "NEW") &&
              moment(data.creationDate.toDate()).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
              data.order.status !== "1004"
            ) {
              this.dayOrderQuantity++;
              this.dayQuantitySum++;
            }

            if (
              (data.order.orderType === "NEW") &&
              moment(data.creationDate.toDate()).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
              data.order.status !== "1008" && 
              data.order.status !== "1004"
            ) {
              this.dayOrderVolume += data.orderSum;
              this.dayVolumeSum += data.orderSum;
            }

            if ((data.order.orderType === "NEW") && moment(data.creationDate.toDate()).format("YYYY-MM") === moment().format("YYYY-MM") && data.order.status !== "1004") {
              this.monthOrderQuantity++;
              this.monthQuantitySum++;
            }

            if (
              (data.order.orderType === "NEW") &&
              moment(data.creationDate.toDate()).format("YYYY-MM") === moment().format("YYYY-MM") &&
              data.order.status !== "1008" && 
              data.order.status !== "1004"
            ) {
              this.monthOrderVolume += data.orderSum;
              this.monthVolumeSum += data.orderSum;
            }

            if (
              (data.order.orderType === "NEW") &&
              moment(data.creationDate.toDate()).isBetween(this.startEndDate.start, this.startEndDate.end) && 
              data.order.status !== "1004" 
              
            ) {
              this.quarterOrderQuantity++;
              this.quarterQuantitySum++;
            }

            if (
              (data.order.orderType === "NEW") &&
              moment(data.creationDate.toDate()).isBetween(this.startEndDate.start, this.startEndDate.end) &&
              data.order.status !== "1008" && 
              data.order.status !== "1004"
            ) {
              this.quarterOrderVolume += data.orderSum;
              this.quarterVolumeSum += data.orderSum;
            }

            if (
              (data.order.orderType === "NEW") &&
              moment(data.creationDate.toDate()).format("YYYY") === moment().format("YYYY") && 
              data.order.status !== "1004"
            ) {
              this.yearOrderQuantity++;
              this.yearQuantitySum++;
            }

            if (
              (data.order.orderType === "NEW") &&
              moment(data.creationDate.toDate()).format("YYYY") === moment().format("YYYY") &&
              data.order.status !== "1008" && 
              data.order.status !== "1004"
            ) {
              this.yearOrderVolume += data.orderSum;
              this.yearVolumeSum += data.orderSum;
            }

            if (
              data.order.orderType === "RENEWAL" &&
              moment(data.creationDate.toDate()).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
              data.order.status !== "1004"
            ) {
              this.dayRenewalQuantity++;
              this.dayQuantitySum++;
            }

            if (
              data.order.orderType === "RENEWAL" &&
              moment(data.creationDate.toDate()).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
              data.order.status !== "1004"
            ) {
              this.dayRenewalVolume += data.orderSum;
              this.dayVolumeSum += data.orderSum;
            }

            if (
              data.order.orderType === "RENEWAL" &&
              moment(data.creationDate.toDate()).format("YYYY-MM") === moment().format("YYYY-MM") && 
              data.order.status !== "1004"
            ) {
              this.monthRenewalQuantity++;
              this.monthQuantitySum++;
            }

            if (
              data.order.orderType === "RENEWAL" &&
              moment(data.creationDate.toDate()).format("YYYY-MM") === moment().format("YYYY-MM") && 
              data.order.status !== "1004"
            ) {
              this.monthRenewalVolume += data.orderSum;
              this.monthVolumeSum += data.orderSum;
            }

            if (
              data.order.orderType === "RENEWAL" &&
              moment(data.creationDate.toDate()).isBetween(this.startEndDate.start, this.startEndDate.end) && 
              data.order.status !== "1004"
            ) {
              this.quarterRenewalQuantity++;
              this.quarterQuantitySum++;
            }

            if (
              data.order.orderType === "RENEWAL" &&
              moment(data.creationDate.toDate()).isBetween(this.startEndDate.start, this.startEndDate.end) && 
              data.order.status !== "1004"
            ) {
              this.quarterRenewalVolume += data.orderSum;
              this.quarterVolumeSum += data.orderSum;
            }

            if (
              data.order.orderType === "RENEWAL" &&
              moment(data.creationDate.toDate()).format("YYYY") === moment().format("YYYY") && 
              data.order.status !== "1004"
            ) {
              this.yearRenewalQuantity++;
              this.yearQuantitySum++;
            }

            if (
              data.order.orderType === "RENEWAL" &&
              moment(data.creationDate.toDate()).format("YYYY") === moment().format("YYYY") && 
              data.order.status !== "1004"
            ) {
              this.yearRenewalVolume += data.orderSum;
              this.yearVolumeSum += data.orderSum;
            }
          } else {
            data.orderSum = 0;
            if (data?.deluxeOrder && data?.deluxeOrder.order_lines) {
              data.orderSum = data.deluxeOrder.order_lines.reduce((acc, line) => {
                return acc + line.unit_cost * line.quantity;
              }, 0);
            } else {
              data.orderSum = data.lineItems.reduce((acc, line) => {
                const price = line.price || 0;
                return acc + price * line.quantity;
              }, 0);
            }

            if (
              moment(data.creationDate.toDate()).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
              (data?.order?.status !== "1004" || data?.status !== "1004")
            ) {
              this.dayTransferQuantity++;
              this.dayQuantitySum++;
            }

            if (
              moment(data.creationDate.toDate()).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
              (data?.order?.status !== "1004" || data?.status !== "1004")
            ) {
              this.dayTransferVolume += data.orderSum;
              this.dayVolumeSum += data.orderSum;
            }

            if (
              moment(data.creationDate.toDate()).format("YYYY-MM") === moment().format("YYYY-MM") && 
              (data?.order?.status !== "1004" || data?.status !== "1004")
            ) {
              this.monthTransferQuantity++;
              this.monthQuantitySum++;
            }

            if (
              moment(data.creationDate.toDate()).format("YYYY-MM") === moment().format("YYYY-MM") && 
              (data?.order?.status !== "1004" || data?.status !== "1004")
            ) {
              this.monthTransferVolume += data.orderSum;
              this.monthVolumeSum += data.orderSum;
            }

            if (
              moment(data.creationDate.toDate()).isBetween(this.startEndDate.start, this.startEndDate.end) && 
              (data?.order?.status !== "1004" || data?.status !== "1004")
            ) {
              this.quarterTransferQuantity++;
              this.quarterQuantitySum++;
            }

            if (
              moment(data.creationDate.toDate()).isBetween(this.startEndDate.start, this.startEndDate.end) && 
              (data?.order?.status !== "1004" || data?.status !== "1004")
            ) {
              this.quarterTransferVolume += data.orderSum;
              this.quarterVolumeSum += data.orderSum;
            }

            if (
              moment(data.creationDate.toDate()).format("YYYY") === moment().format("YYYY") && 
              (data?.order?.status !== "1004" || data?.status !== "1004")
            ) {
              this.yearTransferQuantity++;
              this.yearQuantitySum++;
            }

            if (
              moment(data.creationDate.toDate()).format("YYYY") === moment().format("YYYY") && 
              (data?.order?.status !== "1004" || data?.status !== "1004")
            ) {
              this.yearTransferVolume += data.orderSum;
              this.yearVolumeSum += data.orderSum;
            }
          }
        });

        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
