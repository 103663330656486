<template>
  <b-button variant="dark" v-on:click="exportData()" class="nowrap">Daten&nbsp;exportieren</b-button>
</template>

<script>
import { utils, writeFileXLSX } from 'xlsx';
import moment from 'moment-timezone';

export default {
  name: "ExportDataComponent",
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  methods: {
    exportData() {
      const exportItems = this.items.map(element => {
        const {customer, reseller, order} = element;
        return {
          customernumber: reseller.customernumber,
          reseller: reseller.companyProfile.companyName,
          resellerId: reseller.resellerId,
          customer: customer.companyProfile.companyName,
          customerId: customer.customerId,
          cotermDate: moment(customer.cotermDate).toDate().toLocaleString('de-DE'),
          orderId: order.orderId,
          membershipId: order.membershipId || "",
          status: order.status,
          orderSum: element.orderSum,
          orderType: order.orderType,
          creationDate: element.creationDate.toDate().toLocaleString('de-DE'),
        }
      });
      const worksheet = utils.json_to_sheet(exportItems);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Daten");
      writeFileXLSX(workbook, "acs-dashboard-export.xlsx");
    }
  }
}
</script>