<template>

  <div class="w-100 d-flex justify-content-center">
    <div>

      <div class="text-center" >
        <h3 class="p-3">Massentransfer für {{$route.params.resellerId}}</h3>
        <div v-if="isLoading == false">
          <p class="text-muted">je Zeile eine VIP-Nr.</p>
          <b-form-textarea
            class="my-3"
            id="textarea"
            v-model="vipNrList"
            placeholder="VIP-Nr. eigeben..."
            rows="3"
            max-rows="10"
          ></b-form-textarea>
          <div class="text-left">
            <b-form-checkbox v-model="ignoreOrderReturn">Retourenrecht ignorieren</b-form-checkbox>
            <b-form-checkbox v-model="expireOpenPas">Offene Kaufautorisierungen löschen</b-form-checkbox>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center bg-white my-5" v-if="isLoading == true">
          <b-spinner label="Spinning" variant="secondary"></b-spinner>
        </div>

        <div v-else-if="isLoading !== 'checked' && isLoading !== 'done'">
          <b-button class="mt-3" @click="checkVipNumbers()" >Überprüfen</b-button>
        </div>
        <div class="mt-3" v-else-if="isLoading === 'checked'">
          <p>Die Prüfung der VIP-Nummern ist abgeschlossen</p>
          <b-button @click="transferVipNumbers()">Transfer Starten</b-button>
        </div>

      </div>

      <div class="d-flex align-items-center" v-if="checkedVipNrList.length > 0 && finalTransfer.length == 0" >
        <b-list-group class="w-100 mt-3">

          <b-list-group-item v-for="(item, index) in checkedVipNrList" :key="index">
            <div class="d-flex align-items-center">
              <div :class="statusClass(item.status) ">
                <fa-icon
                  :icon="['fa-regular', statusIcon(item.status)]"
                  class="mr-2"
                ></fa-icon>
              </div>
              <p :class="statusClass(item.status)" style="margin-bottom: 0px !important">{{item.vipNo}}</p>
            </div>
            <div class="mt-2">
              <b-alert variant="danger" :show="!item.status"><div v-html="item.err"></div></b-alert>
            </div>
          </b-list-group-item>

        </b-list-group>
      </div>

      <div class="text-center">
        <span v-if="isLoading == 'done'">
          Der Transfer wurde angestoßen.
        </span>
        <div class="d-flex align-items-center justify-content-center mt-3" v-if="isLoading == 'done'">
          <b-button variant="dark" v-on:click="exportData()">Bericht exportieren</b-button>
        </div>
        <div class="d-flex align-items-center" v-if="finalTransfer.length !== 0">
          <b-list-group class="w-100 mt-3">
            <b-list-group-item v-for="(item, index) in finalTransfer" :key="index">
              <div class="d-flex align-items-center">
                <div :class="statusClass(item.status) ">
                  <fa-icon
                    :icon="['fa-regular', statusIcon(item.status)]"
                    class="mr-2"
                  ></fa-icon>
                </div>
                <p :class="statusClass(item.status)" style="margin-bottom: 0px !important">{{item.vipNo}}</p>
              </div>
              <div class="mt-2">
                <b-alert variant="danger" :show="!item.status"><div v-html="item.err"></div></b-alert>
              </div>
            </b-list-group-item>

          </b-list-group>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { utils, writeFileXLSX } from 'xlsx';

export default {
  name: "MassTransferView",
  data() {
    return {
      isLoading: false,
      checkedVipNrList: [],
      finalTransfer: [],
      vipNrList: "",
      ignoreOrderReturn: false,
      expireOpenPas: false,
    };
  },
  mounted() {

  },
  watch: {
    vipNrList(val) {
      const reg = /[^a-z0-9\r\n?|\n]/gi
      const newStr = val.replace(reg, "")
      const finalStr = newStr.split(/\r?\n/).filter(line => line.trim() !== "").join("\n");
      this.vipNrList = finalStr
    }
  },
  methods: {
    async checkVipNumbers(){
      this.isLoading = true
      const pureArray = this.vipNrList.split(/\r?\n/);
      const eachLine = [...new Set(pureArray)]
      const FetchTransferLicenses = httpsCallable(functions, "dashboard-checkTransferLicenses");

      for await (const vipNo of eachLine) {

        await FetchTransferLicenses({
          vipNr: vipNo,
          query: {
            "ignore-order-return": this.ignoreOrderReturn,
            "expire-open-pas": this.expireOpenPas,
          },
        })
        .then( res => {
          this.checkedVipNrList.push({status: true, vipNo: vipNo, res: res, err: null})
        })
        .catch( async err => {
          const translate = httpsCallable(functions, "translate");
          await translate({
            targetLanguageCode: 'de-DE',
            text: err.message,
          })
          .then((res) => {
            this.checkedVipNrList.push({status: false, vipNo: vipNo, res: null, err: res.data})
          })
          .catch((err) => {
            this.checkedVipNrList.push({status: false, vipNo: vipNo, res: null, err: err.message})
          });
        })
      }
      this.isLoading = 'checked';
    },
    async transferVipNumbers(){
      this.isLoading = true;
      for await (const itm of this.checkedVipNrList) {
        if(itm.status === true) {
          const transferSubscriptions = httpsCallable(functions, "dashboard-transferSubscriptions");
          await transferSubscriptions({
            membershipId: itm.vipNo,
            resellerId: this.$route.params.resellerId,
            previewOffer: itm.res,
            query: {
              "ignore-order-return": this.ignoreOrderReturn,
              "expire-open-pas": this.expireOpenPas,
            },
          })
          .then( () => {
            this.finalTransfer.push({status: "done", vipNo: itm.vipNo, err: null})
          })
          .catch( async err => {
            const translate = httpsCallable(functions, "translate");
            await translate({
              targetLanguageCode: 'de-DE',
              text: err.message,
            })
            .then((res) => {
              this.finalTransfer.push({status: false, vipNo: itm.vipNo, res: null, err: res.data})
            })
            .catch((err) => {
              this.finalTransfer.push({status: false, vipNo: itm.vipNo, res: null, err: err.message})
            });
          })
        }
      }
      this.isLoading = 'done';
    },
    statusIcon(status) {
      if (status) {
        return "fa-circle-check";
      } else {
        return "fa-circle-xmark";
      } 
    },
    statusClass(status) {
      if (status) {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    exportData() {
      const checked = this.checkedVipNrList
      const processed = this.finalTransfer
      const final = [];
      checked.forEach(line=>{
        if(!line.status){
          final.push({vipNr: line.vipNo, status: line.status ? "OK" : "ERROR", error: line.err })
        }
      })
      processed.forEach(line=>{
        if(line.status){
          final.push({vipNr: line.vipNo, status: line.status ? "OK" : "ERROR", error: line.err })
        }
      })
      const newFinal = [];

      final.forEach(line=>{
        newFinal.push({vipNr: line.vipNr, status: line.status, error: line.error ? line.error.replace('<br />', '') : "" })
      })
      const worksheet = utils.json_to_sheet(newFinal);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Daten");
      writeFileXLSX(workbook, `masstransfer_${this.$route.params.resellerId}.xlsx`);
    }
  },
};
</script>

