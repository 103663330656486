import NotFound from "../../views/NotFound.vue";
import SignInView from "../../views/SignInView.vue";
import SignedInView from "../../views/SignedInView.vue";
import HistoryView from "../../views/HistoryView.vue";
import ResellersView from "../../views/ResellersView.vue";
import ResellerView from "../../views/ResellerView.vue";
import CustomersView from "../../views/CustomersView.vue";
import SummaryView from "../../views/SummaryView.vue";
import RegistrationsView from "../../views/RegistrationsView.vue";
import TransfersView from "../../views/TransfersView.vue";
import ProductImportView from "../../views/ProductImportView.vue";
import MassTransferView from "../../views/MassTransferView.vue";
import AnalyticView from "../../views/AnalyticView.vue";
import InsufficientLimitQueueView from "../../views/InsufficientLimitQueueView.vue";

const routes = [
  {
    path: "/",
    redirect: "/sign-in/",
  },
  {
    path: "*",
    redirect: "/404",
  },
  { 
    path: "/404", 
    name: "NotFoundView",
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/sign-in/",
    name: "SignInView",
    component: SignInView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/sign-in/:token",
    name: "TokenSignInView",
    component: SignInView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/-/history/",
    component: SignedInView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/-/history/",
        name: "HistoryView",
        component: HistoryView,
      },
      {
        path: "/-/resellers/",
        name: "ResellersView",
        component: ResellersView,
      },
      {
        path: "/-/resellers/:resellerId",
        name: "ResellerView",
        component: ResellerView,
        props: true,
      },
      {
        path: "/-/customers/",
        name: "CustomersView",
        component: CustomersView,
      },
      {
        path: "/-/summary/",
        name: "SummaryView",
        component: SummaryView,
      },
      {
        path: "/-/registrations/",
        name: "RegistrationsView",
        component: RegistrationsView,
      },
      {
        path: "/-/analytic/",
        name: "AnalyticView",
        component: AnalyticView,
      },
      {
        path: "/-/transfers/",
        name: "TransfersView",
        component: TransfersView,
      },
      {
        path: "/-/mass-transfer/:resellerId",
        component: MassTransferView,
        name: "MassTransferView",
      },
      {
        path: "/-/product-import/",
        name: "ProductImportView",
        component: ProductImportView,
      },
      {
        path: "/-/insufficient-limit-queue/",
        name: "InsufficientLimitQueueView",
        component: InsufficientLimitQueueView,
      },
    ],
  },
];

export default routes;
