<template>
  <b-card>
    <div>
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-start">
          <div class="text-left m-3">
            <b>Reselller: </b>
            <p>{{customerData.resellerName}}</p>
            <p>{{customerData.resellerId}}</p>
          </div>
          <div class="text-left m-3">
          <b> Customer: </b>
          <p> {{customerData.companyProfile.companyName}}</p>
          <p> {{customerData.customerId}}</p>
          </div>
          <div class="text-left m-3">
          <b> Letztes Update: </b>
          <p> {{data.item.lastFetchFromAdobe.toDate().toLocaleDateString("de-DE")}}</p>
          </div>
        </div>
        <div class="d-flex justify-content-start">
          <div class="text-left m-3">
            <p>Zugewiesen Lizenzen: {{ data.item.usedQuantity }}</p>
          </div>
          <div class="text-left m-3">
            <p>Aktive Lizenzen: {{ data.item.subscriptionAnalyticActiveNowCount }}</p>
          </div>
          <div class="text-left m-3">
            <p>Renewal Menge: {{ data.item.subscriptionAnalyticWillRenewalCount }}</p>
          </div>
        </div>
      </div>
      <!-- <hr> -->
        <b-table 
        class="table m-0"
          striped 
          :items="customerData.subscriptions" 
          :fields="fields"
          :sort-by.sync="sortBy">
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
            >
          </template>
          <template #cell(productData)="data">
            {{ data.item.productData.productName | productNameWithoutLevel }}
          </template>
          <template #cell(status)="data">
            {{ data.item.status | subscriptionStatus }}
          </template>
          <template #cell(allocationRate)="data">
            {{ data.item.allocationRate != "Kaufanforderung" && data.item.allocationRate != Infinity ? data.item.allocationRate +  "%" : data.item.allocationRate }}
          </template>
        </b-table>
      <!-- <pre>{{customerData}}</pre> -->
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'Table',
  props: {
    customerData: Object,
    data: Object

  },
  data(){
    return{
      sortBy: '',
      fields: [
        {
          key: 'productData',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'offerId',
          label: 'OfferId',
          sortable: true,
        },
        {
          key: 'autoRenewal.enabled',
          label: 'Atorenewal',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'usedQuantity',
          label: 'Genutzte Lizenzen',
          sortable: true,
        },
        {
          key: 'currentQuantity',
          label: 'Aktive Lizenzen',
          sortable: true,
        },
        {
          key: 'autoRenewal.renewalQuantity',
          label: 'Renewal Menge',
          sortable: true,
        },
        {
          key: 'allocationRate',
          label: 'Zuweisung',
          sortable: true,
        },
      ]
    }
  }
}
</script>

<style scoped>
.card-body {
  padding: 0px !important;
}
thead {
  z-index: 1 !important;
}
</style>
